<script setup lang="ts">
const { getFormattedPrice } = usePrice();

const props = defineProps<{
  currency: string;
  price: number;
}>();

interface FreeDeliveryRules {
  freeShippingAmount: number;
  amountMissingForFreeShipping: number;
  percentMissingForFreeShipping: number;
  lowestShippingPrice: number;
  apiAlias: string;
}

const { cart } = useCart();

const isFreeDelivery = computed(() => {
  return cart.value?.extensions?.deliveryPricesConfig?.isFreeDelivery;
});

const freeShippingData = computed(() => {
  const freeDeliveryExtension = cart.value?.extensions
    ?.deliveryPricesConfig as FreeDeliveryRules;

  return freeDeliveryExtension;
});

const calculateProgress = computed(() => {
  return 100 - freeShippingData.value?.percentMissingForFreeShipping;
});

const missingForFreeShipping = computed(() => {
  return `${getFormattedPrice(freeShippingData.value?.amountMissingForFreeShipping.toFixed(2)) || ""}`;
});
</script>

<template>
  <transition name="fade">
    <div
      v-bind="$attrs"
      class="checkout-free-shipping-bar"
      v-show="!isFreeDelivery"
    >
      <div
        class="checkout-free-shipping-bar__info text-m-sm md:text-sm mb-2 text-left text-validation-green"
      >
        {{
          $t("checkoutFreeShipping.addForFreeShipping", {
            amount: missingForFreeShipping,
          })
        }}
      </div>

      <div
        class="checkout-free-shipping-bar__progress relative h-1 rounded overflow-hidden"
      >
        <div
          class="checkout-free-shipping-bar__progress-overlay h-full transition-[width] duration-500 absolute bg-validation-green"
          :style="{ width: calculateProgress + '%' }"
        ></div>

        <div
          class="checkout-free-shipping-bar__progress-background h-full w-full bg-validation-green opacity-20"
        ></div>
      </div>
    </div>
  </transition>
</template>
